<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="add-lesson"
      ref="add-lesson"
      centered
      title="Update Admin"
      hide-footer
      size="lg"
    >
      <b-card-text>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.full_name"
                  placeholder="Full name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Username"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="newRecord.username"
                  type="text"
                  placeholder="Username"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Password"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-input-group class="mb-2" v-if="update_record.isUpdate">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="is_update_password"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                    >
                      isUpdate
                    </b-form-checkbox>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="newRecord.password"
                    :disabled="!is_update_password"
                    placeholder="Password"
                  />
                </b-input-group>
                <b-form-input v-else
                  v-model="newRecord.password"
                  placeholder="Password"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Group"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-select
                  v-model="newRecord.group_id"
                  class="text-uppercase"
                  :options="list_group"
                  value-field="_id"
                  text-field="name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Domain"
                label-for="h-first-name"
                label-cols-md="4"
              >
                  <b-input-group>
                      <b-form-select v-model="newRecord.domain[0].id" :options="list_domain" text-field="id" value-field="id"></b-form-select>
                      <b-input-group-append>
                          <b-form-input
                              v-model="newRecord.domain[0].limit"
                              placeholder="limit user create"
                          />
                      </b-input-group-append>
                  </b-input-group>


<!--                <b-input-group-->
<!--                  v-for="(item, index) in list_domain"-->
<!--                  class="mb-2"-->
<!--                >-->
<!--                  <b-input-group-prepend is-text>-->
<!--                        &lt;!&ndash;                      chi nhan 1 domain dau tien&ndash;&gt;-->
<!--                      <b-form-radio v-model="newRecord.domain[0].id" :value="item.id" name="some-radios">-->
<!--                          {{ item.id }}-->
<!--                      </b-form-radio>-->
<!--&lt;!&ndash;                    <b-form-checkbox&ndash;&gt;-->
<!--&lt;!&ndash;                      id="checkbox-1"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-model="newRecord.domain[index].id"&ndash;&gt;-->
<!--&lt;!&ndash;                      name="checkbox-1"&ndash;&gt;-->
<!--&lt;!&ndash;                      :value="item.id"&ndash;&gt;-->
<!--&lt;!&ndash;                      :unchecked-value="false"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                      {{ item.id }}&ndash;&gt;-->
<!--&lt;!&ndash;                    </b-form-checkbox>&ndash;&gt;-->
<!--                  </b-input-group-prepend>-->
<!--                  <b-form-input-->
<!--                    v-model="newRecord.domain[0].limit"-->
<!--                    :disabled="newRecord.domain[0].id !== item.id"-->
<!--                    placeholder="limit user create"-->
<!--                  />-->
<!--                </b-input-group>-->
              </b-form-group>
            </b-col>
              <b-col cols="12">
                  <b-form-group
                      label="Licenses"
                      label-for="h-first-name"
                      label-cols-md="4"
                  >
                  <b-form-checkbox-group
                      v-model="newRecord.license_id"
                      :options="list_license"
                      text-field="skuPartNumber"
                      value-field="_id"
                      aria-describedby="ariaDescribedby"
                      name="flavour-4a"
                  ></b-form-checkbox-group>
                  </b-form-group>
              </b-col>
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addNewRecord"
              >
                {{ $t('Submit') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                @click="resetForm"
              >
                {{ $t('Reset') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

    <!-- input search -->
    <b-row class="mb-2">

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-md-0"
      >
        <div class="custom-search d-flex justify-content-center">
          <b-form-group>
            <div class="mb-1">
              <b-button
                v-b-modal.add-lesson
                variant="secondary"
                @click="$router.go(-1)"
              >
                <feather-icon
                  icon="ArrowLeftCircleIcon"
                  class="mr-50"
                />
                <span>{{ $t('Back') }}</span>
              </b-button>
            </div>
            <div class="">
              <b-button
                v-b-modal.add-lesson
                variant="primary"
                @click="resetForm"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('Add Admin') }}
              </b-button>

            </div>

          </b-form-group>

        </div>

      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      style-class="vgt-table bordered striped"
      :line-numbers="true"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'displayName'"
          class="text-nowrap"
        >
          <!--          <b-avatar-->
          <!--            :src="props.row.avatar"-->
          <!--            class="mx-1"-->
          <!--          />-->
          <span class="text-nowrap">{{ props.row.displayName }}</span>
        </span>
        <!-- Column: group -->
        <span v-else-if="props.column.field === 'group_id'">
          <b-badge
            :variant="statusVariant(props.row.group_id.name)"
            class="text-uppercase"
          >
            {{ props.row.group_id.name }}
          </b-badge>
        </span>

        <!-- Column: d0omain -->
        <span v-else-if="props.column.field === 'domain'">
          <div v-for="item in props.row.domain">
              <template v-if="item.id && item.id != 'false'">
                  <div class="mb-1 font-weight-bold">Domain: {{ item.id }}</div>
                    <hr>
                    <div class="mb-1 text-danger">Limited: {{ item.limit }} users</div>
                    <hr>
                    <div class="mb-1 text-success">Created: {{ item.created }} users</div>
              </template>

          </div>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant('Active')">
            {{ `Active` }}
          </b-badge>
        </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'license_id'">
          <div v-for="item in props.row.license_id">
            * {{ item.skuPartNumber }}
          </div>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-modal.add-lesson
                @click="openFormUpdate(props)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteRecord(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormRadio
} from "bootstrap-vue";
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { getUserData } from '@/auth/utils'

export default {
    name: 'List',
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BFormCheckboxGroup,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormRadio,
    },
    data() {
        return {
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: 'Name',
                    field: 'full_name',
                },
                {
                    label: 'Username',
                    field: 'username',
                },
                {
                    label: 'Group',
                    field: 'group_id',
                },
                {
                    label: 'Domain Cpanel',
                    field: 'domain',
                },
                {
                    label: 'Group Created',
                    field: 'limit_group',
                },
                {
                    label: 'License Cpanel',
                    field: 'license_id',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [],
            newRecord: {
                username: '',
                password: '',
                is_active: true,
                full_name: '',
                group_id: '',
                phone: '000',
                domain: [...new Array(1)].map(() => ({ id: false, limit: 0, created: 0 })),
                license_id: [],
                // limit_user_create: 0,
            },
            update_record: {
                isUpdate: false,
                id: '',
            },
            list_group: [],
            list_domain: [],
            list_license: [],
            is_update_password: false,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                // User      : 'light-primary',
                Active : 'light-success',
                all     : 'light-danger',
                Admin     : 'light-warning',
                User      : 'light-info',
                /* eslint-enable key-spacing */
            }

            return (status) => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    async created() {
        await this.$swal_show()
        await this.getListDomain()
        await this.getListGroup()
        await this.getListUser()
        await this.getListLicense()
        await this.$swal_hide()
    },
    methods: {
        async getListLicense() {
            this.$swal_show()
            this.list_license = []
            await this.$http.post('/third-party/list-license')
            .then(async (response) => {
                const { data, message, success } = response.data
                if (success) {
                    this.list_license = data
                    this.$swal_hide()
                } else {
                    this.$swal_hide()
                    this.$error(message)
                }
            })
            .catch((error) => {
                this.$swal_hide()
                const status = _.get(error, 'response.status', 'unknown')
                const reason = _.get(error, 'response.statusText', 'unknown')
                const message = _.get(error, 'response.data.message', 'unknown')

                if (_.isArray(message)) {
                    message.forEach((msg) => {
                        this.$error(msg)
                    })
                } else {
                    this.$error(message)
                }
            })
        },
        async getListDomain() {
            const userData = getUserData()
            if (userData.ability[0].action === 'manage') {
                await this.$http.post('/third-party/list-domain')
                    .then(async (response) => {
                        const { data, message, success } = response.data
                        if (success) {
                            this.list_domain = data.value
                        } else {
                            this.$error(message)
                        }
                    })
                    .catch((error) => {
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            } else {
                this.list_domain = _.filter(userData.domain, (item)=> item.id)
            }
        },
        async getListUserInDomain(domain_id) {
            const response = await this.$http.post('/third-party/list-user-in-domain', { domain_id })
            if (response.data.success) {
                return response.data.data.value.length
            }
            return 0
        },
        async getListUser() {
            this.rows = []
            await this.$http.post('/auth/list-admin')
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.rows = data.docs
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        async getListGroup() {
            this.list_group = []
            await this.$http.post('/auth/list-group')
                .then((response) => {
                    const { data, message, success } = response.data
                    if (success) {
                        this.list_group = data.docs
                    } else {
                        this.$error(message)
                    }
                })
                .catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
        },
        resetForm() {
            this.newRecord = {
                username: '',
                password: '',
                is_active: true,
                full_name: '',
                group_id: '',
                phone: '000',
                domain: [...new Array(this.list_domain.length)].map(() => ({ id: false, limit: 0, created: 0 })),
                license_id: []
                // limit_user_create: 0,
            }
        },
        hideModal(modalID) {
            this.$refs[modalID].hide()
        },
        openFormUpdate(props) {
            this.update_record.isUpdate = true
            this.update_record.id = props.row._id
            console.log('list update',props.row.license_id)
            this.newRecord = {
                username: props.row.username,
                password: '',
                is_active: props.row.isActive,
                full_name: props.row.full_name,
                group_id: props.row.group_id._id,
                phone: props.row.phone,
                domain: props.row.domain,
                license_id: _.map(props.row.license_id,(license)=> license._id),
                // limit_user_create: props.row.limit_user_create,
            }
        },
        async addNewRecord() {
            // console.log(this.newRecord.domain)
            this.$swal_show()
            if (this.update_record.isUpdate) {
                await this.updateRecord(this.update_record.id, this.newRecord)
            } else {
                await this.$http.post('/auth/add-admin', this.newRecord)
                    .then((res) => {
                        const { data, message, success } = res.data
                        if (success) {
                            this.getListUser()
                            this.hideModal('add-lesson')
                            this.$swal({
                                title: 'Good job!',
                                text: 'Add new lesson success!',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                showClass: {
                                    popup: 'animate__animated animate__tada',
                                },
                                buttonsStyling: false,
                            })
                        } else {
                            this.$error(message)
                        }
                    }).catch((error) => {
                        console.error(error)
                        const status = _.get(error, 'response.status', 'unknown')
                        const reason = _.get(error, 'response.statusText', 'unknown')
                        const message = _.get(error, 'response.data.message', 'unknown')

                        if (_.isArray(message)) {
                            message.forEach((msg) => {
                                this.$error(msg)
                            })
                        } else {
                            this.$error(message)
                        }
                    })
            }
            this.$swal_hide()
        },
        async deleteRecord(record) {
            await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    this.$swal_show()
                    await this.$http.post('/auth/delete-admin', { admin_id: record._id })
                        .then((res) => {
                            const { data, message, success } = res.data
                            if (success) {
                                this.getListUser()
                                this.$success('Delete success')
                            } else {
                                this.$error(message)
                            }
                        }).catch((error) => {
                            const status = _.get(error, 'response.status', 'unknown')
                            const reason = _.get(error, 'response.statusText', 'unknown')
                            const message = _.get(error, 'response.data.message', 'unknown')

                            if (_.isArray(message)) {
                                message.forEach((msg) => {
                                    this.$error(msg)
                                })
                            } else {
                                this.$error(message)
                            }
                        })
                    this.$swal_hide()
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelled',
                        text: 'Your imaginary file is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        async updateRecord(id, update) {
            this.$swal_show()
            await this.$http.post('/auth/update-admin', { admin_id: id, update, isUpdatePassword: this.is_update_password })
                .then((res) => {
                    const { data, message, success } = res.data
                    if (success) {
                        this.update_record.isUpdate = false
                        this.update_record.id = ''
                        this.getListUser()
                        this.hideModal('add-lesson')
                        this.$success('Update success')
                    } else {
                        this.$error(message)
                    }
                }).catch((error) => {
                    const status = _.get(error, 'response.status', 'unknown')
                    const reason = _.get(error, 'response.statusText', 'unknown')
                    const message = _.get(error, 'response.data.message', 'unknown')

                    if (_.isArray(message)) {
                        message.forEach((msg) => {
                            this.$error(msg)
                        })
                    } else {
                        this.$error(message)
                    }
                })
            this.$swal_hide()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.back-btn {
  margin-bottom: 14px;
}
</style>
